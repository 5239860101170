import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";

import { changePassword } from "../actions/admin";

const UserSettings = () => {
  const [oldPw, setOldPw] = useState(false);
  const [newPw, setNewPw] = useState(false);
  const [confirmPw, setConfirmPw] = useState(false);

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;

      console.log(value, "valueeeeeee");

      if (isEmpty(value.oldPassword)) {
        error["oldPassword"] = "Field is required";
      } else {
        error["oldPassword"] = "";
      }
      if (isEmpty(value.newPassword)) {
        error["newPassword"] = "Field is required";
      } else {
        error["newPassword"] = "";
      }
      if (isEmpty(value.confirmPassword)) {
        error["confirmPassword"] = "Field is required";
      } else if (value.confirmPassword != value.newPassword) {
        error["confirmPassword"] =
          "Confrim password must match with new password";
      } else {
        error["confirmPassword"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      console.log(formvalue, "formvalue");
      let userData = {
        oldPassword: formvalue.oldPassword,
        newPassword: formvalue.newPassword,
      };
      let isValidate = validation(userData);
      console.log(isValidate, !ObjectIsempty(isValidate), "isValidate+++++++");
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await changePassword(userData);
      if (status) {
        console.log(status, message, result);
      } else {
        console.log(status, message, result);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>

        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12} className="pe-3">
            <Header title={"User Settings"} />
            <div className="mt-3 mt-sm-5 profile_holder">
              <Row>
                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Old Password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={oldPw ? "text" : "password"}
                        placeholder="Enter Old Password"
                        className="rp_singleInput flex-grow-1"
                        name="oldPassword"
                        onChange={handleChange}
                      />
                      <span>{errors.oldPassword}</span>
                      <i
                        class={
                          oldPw ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setOldPw(!oldPw)}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">New Password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={newPw ? "text" : "password"}
                        placeholder="Enter New Password"
                        className="rp_singleInput flex-grow-1"
                        name="newPassword"
                        onChange={handleChange}
                      />
                      <span>{errors.newPassword}</span>

                      <i
                        class={
                          newPw ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setNewPw(!newPw)}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Confirm password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={confirmPw ? "text" : "password"}
                        placeholder="Enter Confirm Password"
                        className="rp_singleInput flex-grow-1"
                        name="confirmPassword"
                        onChange={handleChange}
                      />
                      <span>{errors.confirmPassword}</span>

                      <i
                        class={
                          confirmPw
                            ? "fa-solid fa-eye-slash"
                            : "fa-solid fa-eye"
                        }
                        onClick={() => setConfirmPw(!confirmPw)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <button
                className="orange_small_primary"
                onClick={() => {
                  handleSubmit();
                }}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserSettings;
