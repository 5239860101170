export const paymentType = (type) => {
  switch (type) {
    case "coin_deposit":
      return "Coin";
    case "coin_withdraw":
      return "Coin";
    case "fiat_deposit":
      return "Fiat";
    case "fiat_withdraw":
      return "Fiat";
    default:
      return type;
  }
};

export const address_showing = (item) => {
  if (item && item.toString().length > 10) {
    var slice_front = item.slice(0, 9);
    var slice_end = item.slice(item.length - 9, item.length - 1);
    return slice_front + "...." + slice_end;
  } else return item;
};
