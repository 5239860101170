import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import ReactDatatable from "@ashvin27/react-datatable";
import EditSettingModal from "../Modals/EditSettingModal";
import DeleteSettingModal from "../Modals/DeleteSettingModal";
import { NavLink } from "react-router-dom";
import { getPages, updatePageStatus } from "../actions/settings";

function Settings() {
  useEffect(() => {
    getAllPages();
  }, []);

  // edit modal
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  //   delete modal

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [active, setActive] = useState("buy");

  const [pages, setPages] = useState();
  const [pageStatus, setStatus] = useState();

  const getAllPages = async () => {
    try {
      let { status, message, result } = await getPages();
      console.log(status, "statusstatusstatus");
      if (status == "success") {
        setPages(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (e, index, item) => {
    console.log(e.target.checked, "selectedOption");
    let Pages = [...pages];
    Pages[index]["status"] = e.target.checked;
    setPages(Pages);
    // setStatus(e.target.checked)
    let { status, message, result } = await updatePageStatus({
      _id: item._id,
      status: e.target.checked,
    });
    console.log(status, "statusstatusstatus");
    if (status == "success") {
      getAllPages();
    }
  };

  //   const state = {
  //     records: [
  //       {
  //         sno: <p className="exchange_table_values text-center">1</p>,
  //         name: <p className="exchange_table_values text-center">Demo</p>,
  //         email: (
  //           <p className="exchange_table_values text-center">demo@gmail.com</p>
  //         ),
  //       },
  //       {
  //         sno: <p className="exchange_table_values text-center">2</p>,
  //         name: <p className="exchange_table_values text-center">Demo</p>,
  //         email: (
  //           <p className="exchange_table_values text-center">demo@gmail.com</p>
  //         ),
  //       },
  //     ],
  //   };

  //   const columns = [
  //     {
  //       key: "sno",
  //       text: "S.No",
  //       className: "sno",
  //       align: "center",
  //       sortable: false,
  //     },
  //     {
  //       key: "email",
  //       text: "Email",
  //       className: "cointType",
  //       align: "center",
  //       sortable: false,
  //     },
  //     {
  //       key: "action",
  //       text: "Action",
  //       className: "activity",
  //       align: "center",
  //       sortable: false,
  //       cell: (style) => {
  //         return (
  //           <div className="d-flex justify-content-center align-items-center gap-2">
  //             <button className="cmn_plain_btn" onClick={handleShowEdit}>
  //               <img
  //                 src={require("../assets/images/editer.svg").default}
  //                 className="img-fluid table_activity_img"
  //               />{" "}
  //             </button>
  //             <button className="cmn_plain_btn" onClick={handleShowDelete}>
  //               <img
  //                 src={require("../assets/images/trash.svg").default}
  //                 className="img-fluid table_activity_img"
  //               />{" "}
  //             </button>
  //           </div>
  //         );
  //       },
  //     },
  //   ];

  //   const config = {
  //     page_size: 10,
  //     length_menu: [10, 20, 50],
  //     filename: "Emailtemplates",
  //     no_data_text: "No Email Templates found!",
  //     language: {
  //       length_menu: "Show _MENU_ result per page",
  //       filter: "Filter in records...",
  //       info: "Showing _START_ to _END_ of _TOTAL_ records",
  //       pagination: {
  //         first: "First",
  //         previous: "Previous",
  //         next: "Next",
  //         last: "Last",
  //       },
  //     },
  //     show_length_menu: false,
  //     show_filter: false,
  //     show_pagination: false,
  //     show_info: false,
  //   };

  //   const extraButtons = [
  //     {
  //       className: "btn btn-primary buttons-pdf",
  //       title: "Export TEst",
  //       children: [
  //         <span>
  //           <i
  //             className="glyphicon glyphicon-print fa fa-print"
  //             aria-hidden="true"></i>
  //         </span>,
  //       ],
  //       onClick: (event) => {
  //         console.log(event);
  //       },
  //     },
  //     {
  //       className: "btn btn-primary buttons-pdf",
  //       title: "Export TEst",
  //       children: [
  //         <span>
  //           <i
  //             className="glyphicon glyphicon-print fa fa-print"
  //             aria-hidden="true"></i>
  //         </span>,
  //       ],
  //       onClick: (event) => {
  //         console.log(event);
  //       },
  //       onDoubleClick: (event) => {
  //         console.log("doubleClick");
  //       },
  //     },
  //   ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Page Settings"} />
            <div className="mt-3 mt-sm-5 profile_holder ">
              <Row>
                <Col lg={7} md={8} sm={10} className="mb-4">
                  {/* <div className='settings__holder p-4 rounded-3'> */}

                  {pages && pages.length > 0
                    ? pages.map((item, index) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="rp_label mb-2">{item.pageName}</p>
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={item.status}
                                onChange={(e) => {
                                  handleChange(e, index, item);
                                }}
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        );
                      })
                    : ""}

                  {/* <div className="d-flex align-items-center justify-content-between mt-4">
                    <p className="rp_label mb-2">Dapp</p>
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div> */}
                  {/* </div> */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}

      <EditSettingModal show={showEdit} handleClose={handleCloseEdit} />

      <DeleteSettingModal show={showDelete} handleClose={handleCloseDelete} />
    </>
  );
}

export default Settings;
