import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import ReactDatatable from "@ashvin27/react-datatable";
import OpenTicket from "../Modals/OpenTicket";
import { getAllTikcetList } from "../actions/settings";
import { momentFormat } from "../lib/dateTimeHelper";

function SupportTicket() {
  const [ticket, setTickets] = useState();
  const [pageNumer, setPageNumer] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTicketList();
  }, []);

  // edit modal
  const [showView, setShowView] = useState(false);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);
  const [editRecord, setEditRecord] = useState({});

  const handleShowEditRecord = (record) => {
    setEditRecord(record);
    setShowView(true);
  };

  const getTicketList = async () => {
    let reqData = {
      page: pageNumer,
      limit: limit,
    };

    let { status, message, data, count } = await getAllTikcetList();
    console.log("status, message, data, count:", status, message, data, count);
    if (status == "success") {
      setTickets(data);
      setCount(count);
    }
  };

  const handlePagination = async (index) => {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
    };
    // let skip = (index.page_number - 1) * index.page_size;
    // let loadinterval = index.page_number * index.page_size;
    setPageNumer(index.page_number);
    setLimit(index.page_size);
    const { status, message, result, count } = await getAllTikcetList(reqData);
    setTickets(result);
    setCount(count);
    // setInterval(loadinterval);
  };

  const state = {
    records: [
      {
        title: <p className="exchange_table_values text-center">1</p>,
        description: (
          <p className="exchange_table_values text-center">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
          </p>
        ),
        from: (
          <p className="exchange_table_values text-center">demo@gmail.com</p>
        ),
        time: (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img
              src={require("../assets/images/clock.svg").default}
              className="img-fluid"
            />
            <p className="exchange_table_values text-center">23 Days</p>
          </div>
        ),
        commentCount: <p className="exchange_table_values text-center">0</p>,
      },
      {
        title: <p className="exchange_table_values text-center">2</p>,
        description: (
          <p className="exchange_table_values text-center">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed{" "}
          </p>
        ),
        from: (
          <p className="exchange_table_values text-center">demo@gmail.com</p>
        ),
        time: (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img
              src={require("../assets/images/clock.svg").default}
              className="img-fluid"
            />
            <p className="exchange_table_values text-center">23 Days</p>
          </div>
        ),
        commentCount: <p className="exchange_table_values text-center">0</p>,
      },
    ],
  };

  const columns = [
    {
      key: "tickerId",
      text: "Ticket Id",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "subject",
      text: "Title",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    // {
    //   key: "description",
    //   text: "Description",
    //   className: "exchange_table_values text-center",
    //   align: "center",
    //   sortable: false,
    // },
    {
      key: "email",
      text: "From",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "status",
      text: "status",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "createdAt",
      text: "Time",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return <p>{momentFormat(record.createdAt)}</p>;
      },
    },
    // {
    //   key: "commentCount",
    //   text: "Comment Count",
    //   className: "commentCount",
    //   align: "center",
    //   sortable: false,
    // },
    {
      key: "action",
      text: "Action",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <p className="exchange_activity text-center">
            <i
              class="fa-solid fa-eye"
              onClick={() => {
                handleShowEditRecord(record);
              }}
            />
          </p>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Support Ticket"} />
            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3">
                <ReactDatatable
                  config={config}
                  records={ticket}
                  columns={columns}
                  extraButtons={extraButtons}
                  dynamic={true}
                  total_record={count}
                  onChange={(e) => {
                    handlePagination(e);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}
      <OpenTicket
        show={showView}
        record={editRecord}
        getTicketList={getTicketList}
        handleClose={handleCloseView}
      />
      {/* end of modals */}
    </>
  );
}

export default SupportTicket;
