import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";

import { adminSupportReply } from "../actions/settings";

function OpenTicket({ show, record, getTicketList, handleClose }) {
  useEffect(() => {
    setFormvalue(record);
  }, [record]);

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      console.log(
        value.replyComment,
        isEmpty(value.replyComment),
        "valuevalue"
      );
      if (isEmpty(value.replyComment)) {
        error["replyComment"] = "Field is required";
      } else {
        error["replyComment"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let isValidate = validation(formvalue);
      console.log(isValidate, !ObjectIsempty(isValidate), "isValidate+++++++");
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let replyData = {
        id: record._id,
        reply: formvalue.replyComment,
      };

      let { status, message, result } = await adminSupportReply(replyData);
      console.log("🚀 ~ handleSubmit ~ status:", status);
      if (status == "success") {
        // toastAlert("success", "Pair Added Successfully");
        handleClose();
        getTicketList();
      }
      handleClose();
      getTicketList();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Reply Tickets</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Title</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  readOnly
                  type="text"
                  placeholder="Demo"
                  value={formvalue?.subject}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Description</p>

              <textarea
                readOnly
                value={formvalue?.description}
                rows="4"
                className="w-100 ot_textarea rounded-3 py-2 px-3"
                cols="50"></textarea>
            </div>

            {formvalue?.status == "open" ? (
              <>
                <div className="rp_singleinput_holder mb-3">
                  <p className="rp_label mb-2">Reply</p>

                  <textarea
                    name="replyComment"
                    rows="4"
                    className="w-100 ot_textarea rounded-3 py-2 px-3"
                    cols="50"
                    onChange={handleChange}></textarea>
                </div>
                <span>{errors && errors.replyComment}</span>
                <button
                  className="orange_small_primary"
                  onClick={() => {
                    handleSubmit();
                  }}>
                  Reply
                </button>
              </>
            ) : (
              <>
                <div className="rp_singleinput_holder mb-3">
                  <p className="rp_label mb-2">Reply</p>
                  <textarea
                    value={formvalue.replyComment}
                    rows="4"
                    className="w-100 ot_textarea rounded-3 py-2 px-3"
                    cols="50"></textarea>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OpenTicket;
