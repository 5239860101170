import React, { useRef, useState } from "react";
import io from "socket.io-client";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { useEffect } from "react";
import api from "../config/index"

const MarketOpportunities = () => {
  const [logs, setLogs] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
  
    const socket = io(api.API_URL, { transports: ["polling"] });

    const handleMessage = throttle((message) => {
      message = JSON.parse(message);
      console.log("handleMessage : ", message);
      const color = getRandomColor()
      setLogs((logss)=> {
        console.log("LOGS : ",logss)
         logss.push({
          id: message.id,
          logs: message.logs,
          color: color
        });
        return logss.reverse();
      })
      setTimeout(()=> setActive(false), 1);
      setTimeout(()=> setActive(true), 10);
    }, 1000);

    socket.on("adminlog", (message) => handleMessage(message));
    socket.on("connect", () => {
      setActive(true);
    });

    socket.on("disconnect", () => {
      setActive(false);
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const context = this,
        args = arguments;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(()=>{
    console.log("Logsssss")
  },[logs])

    const state = [
        {
          id: <p className="exchange_table_values text-center">BTC - ETH</p>,
          logs: <p className="exchange_table_values text-center">Pancake</p>,
        },
        {
          id: <p className="exchange_table_values text-center">BTC - ETH</p>,
          logs: <p className="exchange_table_values text-center">Pancake</p>,
        },
        {
          id: <p className="exchange_table_values text-center">BTC - ETH</p>,
          logs: <p className="exchange_table_values text-center">Pancake</p>,
        },
        
      ];
    
      const columns = [
        {
            key: "id",
          text: "#",
          className: "pair",
          align: "center",
            sortable: false,
        },
        {
          key: "logs",
          text: "Server Logs",
          className: "exchangeOne",
          align: "center",
          sortable: false,
        },
        // {
        //     key: "exchangeTwo",
        //     text: "Exchange 2",
        //     className: "exchangeTwo",
        //     align: "center",
        //     sortable: false,
        //   },
        //   {
        //     key: "priceDiffer",
        //     text: "Price Differerence",
        //     className: "priceDiffer",
        //     align: "center",
        //     sortable: false,
        //   },
          
        //   {
        //     key: "action",
        //     text: "Action",
        //     className: "activity",
        //     align: "center",
        //     sortable: false,
        //     cell : (style) => {
        //         return (
        //            <div className='d-flex justify-content-center align-items-center gap-2'>
        //             <button className='cmn_plain_btn' ><img src={require('../assets/images/editer.svg').default} className='img-fluid table_activity_img' /> </button>
        //             <button className='cmn_plain_btn' ><img src={require('../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button>
        //            </div>
        //         )
        //     }
        // },
        
      ];
    
      const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
      };
    
      const extraButtons = [
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export TEst",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-print"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            console.log(event);
          },
        },
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export TEst",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-print"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            console.log(event);
          },
          onDoubleClick: (event) => {
            console.log("doubleClick");
          },
        },
      ];
  return (
    <>
<Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col  xl={10} lg={12}>
            <Header title={'Market Opportunities'}/>

            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              {/* <div className="exchange_table_holder dashboard_box rounded-3 mt-4"> */}
              <table border={'2px'} width={'100%'}>
                <tr>
                 <th>LOGS</th>
                 </tr>
                 { active && logs.length > 0 && logs.map((item)=>
                  <tr  style={{color:item.color}} >
                    <td> { item.logs } </td>
                  </tr>
                 )}
              </table>
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MarketOpportunities