import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'


// import lib
import isLogin from '../lib/isLogin';

const ConditionRoute = ({ component: Component, layout: Layout, auth, type, ...rest }) => {
    console.log(Component,Layout,"Component",isLogin())
    return (
  
        <Route
        {...rest}
            render={props => {
                console.log("ConditionRoute",type ,type == 'auth' && isLogin() == true, type == 'private' && isLogin() != true)
                if (type == 'auth' && isLogin() == true) {
                    return <Redirect to="/dashboard" />
                } else if (type == 'private' && isLogin() != true) {
                    return <Redirect to="/" />
                }
                console.log("ConditionRoute",props)
                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;