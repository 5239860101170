import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import EditPairModal from "../Modals/EditPairModal";
import DeletePairModal from "../Modals/DeletePairModal";
import AddPairModal from "../Modals/AddPairModal";
import { getPairs, getPair } from "../actions/currency";
import { address_showing } from "../lib/displayStatus";

const Pairs = () => {
  useEffect(() => {
    getAllPairs();
  }, []);

  // add pari modal
  const [showAddPair, setShowAddPair] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [deleteRecord, setDeleteRecord] = useState({});

  const handleShowAddPair = () => setShowAddPair(true);
  const handleCloseAddPair = () => setShowAddPair(false);

  // edid pari modal
  const [showEditPair, setShowEditPair] = useState(false);

  const handleShowEditPair = (record) => {
    setEditRecord(record);
    setShowEditPair(true);
  };
  const handleCloseEditPair = () => setShowEditPair(false);

  // delete pair modal

  const [showDeletePair, setShowDeletePair] = useState(false);

  const handleShowDeletePair = (record) => {
    setDeleteRecord(record);
    setShowDeletePair(true);
  };
  const handleCloseDeletePair = () => setShowDeletePair(false);

  const [pairs, setPairs] = useState([]);

  pairs?.map((val) => {
    val.token1.address = address_showing(val.token1.address);
    val.token2.address = address_showing(val.token2.address);
  });

  const state = [
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
  ];

  const getAllPairs = async () => {
    try {
      let { status, message, result } = await getPairs();
      console.log(status, message, result, "status,message,result");
      setPairs(result);
    } catch (err) {
      console.log("getAllPairs_err", err);
    }
  };

  console.log(pairs, "asdfasfasdfasdf");

  const columns = [
    {
      key: "name",
      text: "Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "token1",
      text: "Token 1 Address",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        console.log(record?.token1?.name, "recordrecordrecord");
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{record?.token1?.address}</p>
          </div>
        );
      },
    },
    {
      key: "token2",
      text: "Token 2 Address",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{record?.token2?.address}</p>
          </div>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowEditPair(record);
              }}>
              <img
                src={require("../assets/images/editer.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowDeletePair(record._id);
              }}>
              <img
                src={require("../assets/images/trash.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Pairs"} />
            <div className="text-end mb-4">
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleShowAddPair(true);
                }}>
                Add
              </button>
            </div>
            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={pairs}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}
      <AddPairModal
        show={showAddPair}
        getAllPairs={getAllPairs}
        handleClose={handleCloseAddPair}
      />

      <EditPairModal
        show={showEditPair}
        record={editRecord}
        handleClose={handleCloseEditPair}
      />
      <DeletePairModal
        show={showDeletePair}
        record={deleteRecord}
        getAllPairs={getAllPairs}
        handleClose={handleCloseDeletePair}
      />
      {/* end of modals */}
    </>
  );
};

export default Pairs;
