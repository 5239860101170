

const initialState = {
    switcher : false,
}

function switcher( state = initialState,action){
    switch(action.type){
        case 'switching'  :{
            return {
                ...state,...action.value
            }
        }
        default :{
            return initialState
        }
    }
}

export default switcher

