import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

const TradeHistory = () => {
    const state = [
        {
            id: <p className="exchange_table_values text-center">#123</p>,
            fromExchange: <p className="exchange_table_values text-center">Pancakeswap</p>,
            toExchange: <p className="exchange_table_values text-center">Sushiswap</p>,
            amountIn: <p className="exchange_table_values text-center">100</p>,
            amountOut: <p className="exchange_table_values text-center">50</p>,
            profit: <p className="exchange_table_values text-center">2%</p>,
            status: <p className="exchange_table_values text-center">Pending</p>,
          
        },
        {
            id: <p className="exchange_table_values text-center">#123</p>,
            fromExchange: <p className="exchange_table_values text-center">Pancakeswap</p>,
            toExchange: <p className="exchange_table_values text-center">Sushiswap</p>,
            amountIn: <p className="exchange_table_values text-center">100</p>,
            amountOut: <p className="exchange_table_values text-center">50</p>,
            profit: <p className="exchange_table_values text-center">2%</p>,
            status: <p className="exchange_table_values text-center">Executed</p>,
          
        },
        
      ];
    
    
      const columns = [
        {
            key: "id",
          text: "ID",
          className: "id",
          align: "center",
            sortable: false,
        },
        {
          key: "fromExchange",
          text: "From Exchange",
          className: "fromExchange",
          align: "center",
          sortable: false,
        },
        {
            key: "toExchange",
            text: "To Exchange",
            className: "toExchange",
            align: "center",
            sortable: false,
          },
          {
            key: "amountIn",
          text: "Amount In",
          className: "amountIn",
          align: "center",
            sortable: false,
        },
        {
            key: "amountOut",
          text: "amount Out",
          className: "amountOut",
          align: "center",
            sortable: false,
        },
        {
            key: "profit",
          text: "Profit",
          className: "profit",
          align: "center",
            sortable: false,
        },
        {
            key: "status",
          text: "Status",
          className: "status",
          align: "center",
            sortable: false,
        },
        //   {
        //     key: "action",
        //     text: "Action",
        //     className: "activity",
        //     align: "center",
        //     sortable: false,
        //     cell : (style) => {
        //         return (
        //            <div className='d-flex justify-content-center align-items-center gap-2'>
        //             <button className='cmn_plain_btn' ><img src={require('../assets/images/editer.svg').default} className='img-fluid table_activity_img' /> </button>
        //             <button className='cmn_plain_btn' ><img src={require('../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button>
        //            </div>
        //         )
        //     }
        // },
        
      ];
    
      const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
      };
    
      const extraButtons = [
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export TEst",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-print"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            console.log(event);
          },
        },
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export TEst",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-print"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            console.log(event);
          },
          onDoubleClick: (event) => {
            console.log("doubleClick");
          },
        },
      ];
  return (
    <>
    <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col  xl={10} lg={12}>
            <Header title={'Pairs'}/>

            <div className="common_page_scroller mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TradeHistory