import React from "react";
import { Modal } from "react-bootstrap";
import { deleteSingleExchange } from "../actions/currency";

const DeleteExchangeModal = ({
  show,
  handleClose,
  record,
  getAllExchanges,
}) => {
  const deleteExchage = async () => {
    try {
      let { status, message, result } = await deleteSingleExchange({
        _id: record,
      });
      console.log(status, message, result, "status, message, result");
      if (status == "success") {
        getAllExchanges();
      }
    } catch (err) {
      console.log("deletePair_err");
    }
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Delete Exchange</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3">
            <p className="dash_graymed_text">
              Are you sure delete this exchange.
            </p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                handleClose();
                deleteExchage();
              }}>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteExchangeModal;
