import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import { addNewPlan } from "../actions/currency";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

const initialFormValue = {
  name: "",
  fee: "",
  validity: "",
  duration: "",
  flashLoanAmount:"",
  // flashLoanLimit:"",
  highlights: [],
};

function AddSubscription() {
  const [count, setCount] = useState(1);

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  // const navigate = useNavigate();
  const history = useHistory();
  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      console.log(value, "value+++++++++++++");
      if (isEmpty(value.name)) {
        console.log(isEmpty(value.name), "nameeeeee");
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.fee)) {
        error["fee"] = "Field is required";
      } else {
        error["fee"] = "";
      }
      if (ObjectIsempty(value.validity)) {
        error["validity"] = "Field is required";
      } else {
        error["validity"] = "";
      }
      if (isEmpty(value.duration)) {
        error["duration"] = "Field is required";
      } else {
        error["duration"] = "";
      }
      if (isEmpty(value.highlights)) {
        error["highlights"] = "Field is required";
      } else {
        error["highlights"] = "";
      }
      if (isEmpty(value.flashLoanAmount)) {
        error["flashLoanAmount"] = "Field is required";
      } else {
        error["flashLoanAmount"] = "";
      }
      // if (isEmpty(value.flashLoanLimit)) {
      //   error["flashLoanLimit"] = "Field is required";
      // } else {
      //   error["flashLoanLimit"] = "";
      // }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      const { name, fee, validity, duration, highlights } = formvalue;
      console.log(formvalue.validity, "formvalue.validity");
      let oj = {
        name: formvalue.name,
        fee: formvalue.fee,
        validity: selectedOption1,
        duration: formvalue.duration,
        highlights: formvalue.highlights,
        flashLoanAmount:formvalue.flashLoanAmount,
        // flashLoanLimit:formvalue.flashLoanLimit
      };
      let isValidate = validation(oj);
      console.log(isValidate, !ObjectIsempty(isValidate), "isValidate+++++++");
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await addNewPlan(oj);
      if (status == "success") {
        console.log(status == "success", "status");
        history.push("/subscription");
        // toastAlert("success", "Pair Added Successfully");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const handleHighlights = async (e, index) => {
    try {
      let arr = formvalue.highlights;
      arr[index] = e.target.value;
      let Formvalue = { ...formvalue, ["highlights"]: arr };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
      console.log(Formvalue, index, "EEEEEEE");
    } catch (err) {
      console.log(err, "handleHighlights");
    }
  };

  const options = [
    { value: "Hours", label: "hours" },
    { value: "Days", label: "days" },
    { value: "Weeks", label: "weeks" },
    { value: "Months", label: "months" },
    { value: "Years", label: "years" },
  ];
  const [selectedOption1, setSelectedOption1] = useState("Theme 1");
  const handleChange1 = (e) => {
    console.log(e, "selectedOptionselectedOption");
    let Formvalue = { ...formvalue, ["validity"]: e.value };
    setFormvalue(Formvalue);
    setSelectedOption1(e.value);
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#5fe44b"
          : isSelected
          ? "#5fe44b"
          : isFocused
          ? "#5fe44b"
          : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "1px solid #2c2c2c",
      borderRadius: "5px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12} className="pe-3">
            <Header title={"Add Subscription"} />
            <div className="common_page_scroller mt-4 mt-sm-5 pe-2">
              <Row className="profile_holder">
                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Plan name</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Plan name"
                        name="name"
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Price</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="number"
                        placeholder="Price"
                        name="fee"
                        min={1}
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                      <span className="text-danger">{errors.fee}</span>
                    </div>
                  </div>
                </Col>


                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Flash Loan Amount</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="number"
                        placeholder="Flash Loan Amount"
                        name="flashLoanAmount"
                        min={1}
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                      <span className="text-danger">{errors.flashLoanAmount}</span>
                    </div>
                  </div>
                </Col>



                {/* <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Flash Loan Limit</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="number"
                        placeholder="Flash Loan Limit"
                        name="flashLoanLimit"
                        min={1}
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                      <span className="text-danger">{errors.flashLoanLimit}</span>
                    </div>
                  </div>
                </Col> */}



                

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Validity</p>

                    <Select
                      onChange={(e) => handleChange1(e)}
                      options={options}
                      name="validity"
                      defaultValue="theme1"
                      styles={stylesgraybg}
                      isSearchable={false}
                      classNamePrefix="customselect"
                      value={
                        selectedOption1 && selectedOption1?.length > 0
                          ? options?.filter((el) => {
                              if (selectedOption1.includes(el.value)) {
                                return el;
                              }
                            })
                          : []
                      }
                      // defaultInputValue="Theme 1"
                      // menuIsOpen
                    />
                    <span className="text-danger">{errors.validity}</span>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Duration</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="number"
                        name="duration"
                        min={1}
                        placeholder="Duration"
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                      <span className="text-danger">{errors.duration}</span>
                    </div>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Highlights</p>
                    {[...Array(count)].map((item, index) => (
                      <div className="mb-3">
                        <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                          <input
                            type="text"
                            name="highlights"
                            onChange={(e) => handleHighlights(e, index)}
                            placeholder="Highlights"
                            className="rp_singleInput flex-grow-1"
                          />
                        </div>
                        <div className="d-flex gap-3 justify-content-end mt-3">
                          <button
                            className="subs_add_btn rounded-2"
                            onClick={() => setCount(count + 1)}>
                            <i class="fa-solid fa-plus" />
                          </button>
                          {count != 1 && (
                            <button
                              className="subs_dlt_btn rounded-2"
                              onClick={() => setCount(count - 1)}>
                              <i class="fa-solid fa-trash" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <div className="d-flex gap-3 mb-5">
                <button className="secondary_btn">Cancel</button>
                <button
                  className="orange_primary_btn"
                  onClick={() => {
                    handleSubmit();
                  }}>
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddSubscription;
