// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";
import Config from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/login`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthorization(respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const adminForgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/forgot-Password`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyOtp = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/verify-Admin-Otp`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const adminResetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/reset-password`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getAdminList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/admin/subAdmin`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const creatAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/sub-admin`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const EditAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/edit-admin`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const EditAdminStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/edit-admin-status`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGeoInfoData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${Config.getGeoInfo}`,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const loginHisPagination = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/admin/login-history`,
      params: data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/admin/change-password`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getProfile = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/admin/get-profile`,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const sendMail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/send-mail`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const editProfile = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/admin/edit-profile`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: err.response.data.status,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getUserList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/get-users`,
      data,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const EditUserStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/change-user-status`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const addNewUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/add-Users`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const addNewUserCSV = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/admin/add-Users-CSV`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
