// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";
import Config from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";

export const getSchedules = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/market/get-schedules`,
      data,
    });
    console.log(respData, "respData++++++++");
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const editSchedules = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/market/change-schedule-status`,
      data
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
