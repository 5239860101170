import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import EditExchangeModal from "../Modals/EditExchangeModal";
import DeleteExchangeModal from "../Modals/DeleteExchangeModal";
import AddExchangeModal from "../Modals/AddExchageModal";
import { getSchedules } from "../actions/currency";
import { address_showing } from "../lib/displayStatus";
import DeleteScheduleModal from "../Modals/DeleteScheduleModal";
const Schedules = () => {
  useEffect(() => {
    getAllSchedules();
  }, []);

  // add Exchange modal
  const [showAddExchange, setShowAddExchange] = useState(false);

  const handleShowAddExchange = () => setShowAddExchange(true);
  const handleCloseAddExchange = () => setShowAddExchange(false);

  // edid Exchange modal
  const [showEditExchange, setShowEditExchange] = useState(false);
  const [editRecord, setEditRecord] = useState();

  const handleShowEditExchange = (record) => {
    setEditRecord(record);
    setShowEditExchange(true);
   
  };

  const handleCloseEditExchange = () => setShowEditExchange(false);

  // delete Exchange modal

  const [showDeleteExchange, setShowDeleteExchange] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();

  const handleShowDeleteExchange = (record) => {
    setDeleteRecord(record);
    setShowDeleteExchange(true);
    setSchedules(schedules)
  };

  const handleCloseDeleteExchange = () => setShowDeleteExchange(false);

  const [schedules, setSchedules] = useState([]);


  // schedules?.map((val) => {
  //   val.factory = address_showing(val.factory);
  //   val.router = address_showing(val.router);
  // });


  const getAllSchedules = async () => {
    try {
      let { status, message, result } = await getSchedules();
      console.log(status, message, result, "status, message, result");
     
      if (status) {
        var res = [];
        await result.map((item,index)=>{
          res.push({
            _id: item._id,
            symbol: (
                <p className="exchange_table_values text-center">{item?.symbol}</p>
              ),
              user: (
                <p className="exchange_table_values text-center">{ address_showing(item?.user)}</p>
              ),
              difference: (
                <p className="exchange_table_values text-center">{item?.difference}</p>
              ),
              status: (
                <p className="exchange_table_values text-center">{item?.status}</p>
              )
          })
        })
        setSchedules(res);
      }
    } catch (err) {
      console.log("getAllSchedules_err", err);
    }
  };

  const state = [
    {
      name: (
        <p className="exchange_table_values text-center">{schedules?.name}</p>
      ),
      factoryRouterAddress: (
        <p className="exchange_table_values text-center">{schedules?.factory}</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      factoryRouterAddress: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      factoryRouterAddress: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
    },
  ];

  const columns = [
    {
      key: "symbol",
      text: "Pair Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "user",
      text: "User",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "difference",
      text: "Differences",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    // status
    {
      key: "action",
      text: "Action",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            {/* <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowEditExchange(record);
              }}>
              <img
                src={require("../assets/images/editer.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button> */}
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowDeleteExchange(record._id);
              }}>
              <img
                src={require("../assets/images/trash.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Schedules"} />
            {/* <div className="text-end mb-4">
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleShowAddExchange(true);
                }}>
                Add
              </button>
            </div> */}

            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={schedules}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}
      {/* <AddExchangeModal
        show={showAddExchange}
        handleClose={handleCloseAddExchange}
      />

      <EditExchangeModal
        show={showEditExchange}
        record={editRecord}
        handleClose={handleCloseEditExchange}
      />*/}
      <DeleteScheduleModal
        show={showDeleteExchange}
        record={deleteRecord}
        handleClose={handleCloseDeleteExchange}
      /> 
      {/* end of modals */}
    </>
  );
};

export default Schedules;
