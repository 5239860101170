import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import WalletDetail from '../Modals/WalletDetail';

function UserWallet() {
   // edit modal
   const [showView, setShowView] = useState(false);

   const handleCloseView = () => setShowView(false);
   const handleShowView = () => setShowView(true);
  const state = {
    records: [
      {
        'sno': <p className='exchange_table_values text-center'>1</p>,
        "name": <p className='exchange_table_values text-center'>Demo</p>,
        "email": <p className='exchange_table_values text-center'>demo@gmail.com</p>,

      },
      {
        'sno': <p className='exchange_table_values text-center'>2</p>,
        "name": <p className='exchange_table_values text-center'>Demo</p>,
        "email": <p className='exchange_table_values text-center'>demo@gmail.com</p>,
      },
     
    ]
}

const columns = [
  {
    key: "sno",
    text: "S.No",
    className: "sno",
    align: "center",
    sortable: false,
},
    {
        key: "name",
        text: "Name",
        className: "name",
        align: "center",
        sortable: false,
    },
    {
        key: "email",
        text: "Email",
        className: "cointType",
        align: "center",
        sortable: false,
        
    },
   {
        key: "action",
        text: "Action",
        className: "activity",
        align: "center",
        sortable: false,
        cell : (style) => {
            return (
                <p className='exchange_activity text-center'><i class="fa-solid fa-eye" onClick={handleShowView} /></p>
            )
        }
    },
];

const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
        },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
};

const extraButtons =[
    {
        className:"btn btn-primary buttons-pdf",
        title:"Export TEst",
        children:[
            <span>
            <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
            </span>
        ],
        onClick:(event)=>{
            console.log(event);
        },
    },
    {
        className:"btn btn-primary buttons-pdf",
        title:"Export TEst",
        children:[
            <span>
            <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
            </span>
        ],
        onClick:(event)=>{
            console.log(event);
        },
        onDoubleClick:(event)=>{
            console.log("doubleClick")
        }
    },
]
  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>
    <Row>

    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
        <Sidebar/>
    </Col>
    <Col xl={10} lg={12}>
        <Header title={'User Wallet'}/>
        <div className='common_page_scroller mt-5 pe-2'>
        <div className='exchange_table_holder dashboard_box rounded-3'>
<ReactDatatable
                    config={config}
                    records={state.records}
                    columns={columns}
                    extraButtons={extraButtons}
                />
</div>
          </div>
    </Col>
    </Row>
    </Container>

    {/* modals */}
    <WalletDetail show={showView} handleClose={handleCloseView} />
    {/* end of modals */}

    </>
  )
}

export default UserWallet