// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";
import Config from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";

export const getPages = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/get-page`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const updatePageStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/update-page-status`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const addSiteSettigs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/addSiteSetting`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const getSiteSettigs = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/getSiteSetting`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const updateSiteSettigs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/updateSiteSetting`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getsupportCategory = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/get-supportCategory`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const addsupportCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/add-supportCategory`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const editsupportCategory = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/update-supportCategory`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getAllTikcetList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/ticketList`,
      data,
    });
    return {
      status: "success",
      loading: false,
      data: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};


export const adminSupportReply = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/settingapi/admin-Reply`,
      data,
    });
    return {
      status: "success",
      loading: false,
      data: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
