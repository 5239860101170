import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { addNewUser } from "../actions/admin";
import { getAllPlans } from "../actions/currency";

const initialFormValue = {
  walletAddress: "",
  name: "",
  plan: [],
};

const AddUsersModal = ({ show, handleClose, getUserAllList }) => {
  //   console.log(record, "record");

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const [plans, setPlans] = useState({});
  const [options1, setOptions1] = useState();

  const getAllPlansList = async () => {
    try {
      let { status, message, result } = await getAllPlans();
      if (status == "success") {
        setPlans(result);
        setOptions1(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getAllPlansList();
  }, []);

  options1?.map((val) => {
    val["label"] = val.name;
    val["value"] = val.name;
  });

  const addressRegex = "^(0x)[0-9A-Fa-f]{40}$";

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      if (name == "walletAddress") {
        var pattern = new RegExp(addressRegex);
        console.log(!pattern.test(value), "patternnnnnnnn");
        if (!pattern.test(value)) {
          return;
        }
      }
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      var pattern = new RegExp(addressRegex);
      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.walletAddress)) {
        error["walletAddress"] = "Field is required";
      } 
      else if (isEmpty(value.walletAddress?.match(pattern))) {
        error["walletAddress"] = "Enter Valid Address";
      } 
      else {
        error["walletAddress"] = "";
      }
      if (isEmpty(value.plan)) {
        error["plan"] = "Field is required";
      } else {
        error["plan"] = "";
      }

      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let userData = {
        name: formvalue.name,
        walletAddress: formvalue.walletAddress,
        plan: selectedOption1._id,
      };
      let isValidate = validation(userData);
      console.log(isValidate, !ObjectIsempty(isValidate), "isValidate+++++++");
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await addNewUser(userData);
      if (status == "success") {
        // toastAlert("success", "Pair Added Successfully");
        console.log(status == "success", "SSSSSSSSSS");
        getUserAllList();
        setFormvalue({});
        setSelectedOption1({});
        handleClose();
        setErrors({});
      }
      handleClose();
      setFormvalue({});
      setErrors({});
      setSelectedOption1({});
      getUserAllList();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const HandleClose = async ()=>
  {
    handleClose();
      setFormvalue(initialFormValue);
      setErrors({});
      setSelectedOption1({});
  }

  const options = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];

  console.log(options1, "options1options1");

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#5fe44b"
          : isSelected
          ? "#5fe44b"
          : isFocused
          ? "#5fe44b"
          : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const [selectedOption1, setSelectedOption1] = useState("Theme 1");

  const handleChange1 = (e) => {
    setSelectedOption1(e);
    let Formvalue = { ...formvalue, ['plan']: e };
    setFormvalue(Formvalue);
    setErrors(validation(Formvalue));
    console.log(selectedOption1, "selectedOption1");
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Add User</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={HandleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div> */}

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter User Name"
                  className="rp_singleInput flex-grow-1"
                />
              </div>
              <span>
                <span className="text-danger">{errors?.name}</span>
              </span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Wallet Address</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  name="walletAddress"
                  onChange={handleChange}
                  value={formvalue?.walletAddress}
                  placeholder="Enter Wallet Address"
                  className="rp_singleInput flex-grow-1"
                />
              </div>
              <span>
                <span className="text-danger">{errors?.walletAddress}</span>
              </span>
            </div>

            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Status</p>
              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  value={selectedOption1}
                  onChange={(e) => handleChange1(e)}
                  options={options}
                  defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"
                  // defaultInputValue="Theme 1"
                  // menuIsOpen
                />
              </div>
            </div> */}

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Plan</p>
              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  onChange={(e) => handleChange1(e)}
                  options={options1}
                  name="plan"
                  defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"

                  // defaultInputValue="Theme 1"
                  //   menuIsOpen
                />
              </div>
              <span>
                <span className="text-danger">{errors?.plan}</span>
              </span>
            </div>

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUsersModal;
