import { jwtDecode } from "jwt-decode";

// import constant
import { SET_AUTHENTICATION } from "../constant";
import { decode } from "punycode";

export const decodeJwt = (token, dispatch) => {
  if (token) {
    token = token.replace("Bearer ", "");
    const decoded = jwtDecode(token);
    console.log(decoded, "decoded");
    if (decoded) {
      dispatch({
        type: SET_AUTHENTICATION,
        authData: {
          isAuth: true,
          userId: decoded._id,
          restriction: decoded.restriction,
          role: decoded.role,
        },
      });
    }
  }
};
