import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

function Exchange() {
  const state = [
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      userId: <p className="exchange_table_values text-center">2523153</p>,
      network: <p className="exchange_table_values text-center">BTC</p>,
      address: (
        <p className="exchange_table_values exchange_table_address text-center">
          yu676869669869
        </p>
      ),
      created: (
        <p className="exchange_table_values text-center">
          08/02/2024 , 15:24:35 PM
        </p>
      ),
      status: <p className="exchange_table_values text-center">Completed</p>,
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      userId: <p className="exchange_table_values text-center">2523153</p>,
      network: <p className="exchange_table_values text-center">BTC</p>,
      address: (
        <p className="exchange_table_values exchange_table_address text-center">
          yu676869669869
        </p>
      ),
      created: (
        <p className="exchange_table_values text-center">
          08/02/2024 , 15:24:35 PM
        </p>
      ),
      status: <p className="exchange_table_values text-center">Failed</p>,
    },
    {
        name: <p className="exchange_table_values text-center">Demo</p>,
        userId: <p className="exchange_table_values text-center">2523153</p>,
        network: <p className="exchange_table_values text-center">BTC</p>,
        address: (
          <p className="exchange_table_values exchange_table_address text-center">
            yu676869669869
          </p>
        ),
        created: (
          <p className="exchange_table_values text-center">
            08/02/2024 , 15:24:35 PM
          </p>
        ),
        status: <p className="exchange_table_values text-center">Pending</p>,
      },
  ];

  // console.log(state.records[0].status.props,"asdfasfasdfasdf");

  const columns = [
    {
        key: "name",
      text: "UserName",
      className: "name",
      align: "center",
        sortable: false,
    },
    {
      key: "userId",
      text: "User ID",
      className: "userId",
      align: "center",
      sortable: false,
    },
    {
      key: "network",
      text: "Network",
      className: "network",
      align: "center",
      sortable: false,
    },
    {
      key: "address",
      text: "address",
      className: "address",
      align: "center",
      sortable: false,
    },
    {
      key: "created",
      text: "Created",
      className: "created",
      align: "center",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "center",
      sortable: false,
      cell: (rec) =>  <div className="text-center"><button className={`rounded-4 ${rec.status.props.children == 'Completed' ? 'exchange_completed' : rec.status.props.children == 'Pending' ? 'exchange_pending' : rec.status.props.children == 'Failed' ? 'exchange_fail' : ''}`} >{rec.status.props.children}</button>
      {console.log(rec.status.props.children,'recrecrec')}
      </div>,
    },
    //  {
    //     key: "activity",
    //     text: "Activity",
    //     className: "activity",
    //     align: "center",
    //     sortable: false,
    //     cell : (style) => {
    //         return (
    //             <p className='exchange_activity text-center'><i class="fa-solid fa-eye"></i></p>
    //         )
    //     }
    // },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col  xl={10} lg={12}>
            <Header title={'Transaction'}/>

            <div className="common_page_scroller mt-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Exchange;
