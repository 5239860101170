import React from "react";
import { Modal } from "react-bootstrap";
import { deleteSingleToken } from "../actions/currency";

const DeleteTokenModal = ({ show, handleClose, record, getTokens }) => {
  const deleteToken = async () => {
    try {
      let { status, message, result } = await deleteSingleToken({
        _id: record,
      });
      console.log(status, message, result, "status, message, result");
      if (status == "success") {
        getTokens();
      }
    } catch (err) {
      console.log("deletePair_err");
    }
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Delete Pairs</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3">
            <p className="dash_graymed_text">
              Are you sure delete these pairs.
            </p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                handleClose();
                deleteToken();
              }}>
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteTokenModal;
