const nav = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/grid.svg").default,
  },
  {
    path: "/supportTicket",
    name: "Support Ticket",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/ticket.svg").default,
  },
  {
    path: "/settings",
    name: "Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/pageSetting.svg").default,
  },
  {
    path: "/siteSettings",
    name: "Site Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/siteSetting.svg").default,
  },
  {
    path: "/userSettings",
    name: "Admin Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/userSetting.svg").default,
  },
  {
    path: "/users",
    name: "Users",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/userSetting.svg").default,
  },

  {
    path: "/tokens",
    name: "Tokens",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/network.svg").default,
  },

  {
    path: "/pairs",
    name: "Pairs",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/network.svg").default,
  },

  {
    path: "/exchanges",
    name: "Exchanges",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/exchange.svg").default,
  },

  {
    path: "/subscription",
    name: "Subscription",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/prime.svg").default,
  },

  {
    path: "/tradeHistory",
    name: "Trade History",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/trade.svg").default,
  },
  {
    path: "/scheduleHistory",
    name: "Schedule History",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/scheduler.svg").default,
  },

  {
    path: "/subAdmin",
    name: "Sub Admin",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/subadmin.svg").default,
  },

  // {
  //   path: "/userWallet",
  //   name: "User Wallet",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/network.svg").default,
  // },
  // {
  //   path: "/network",
  //   name: "Network",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/network.svg").default,
  // },
  // {
  //   path: "/exchange",
  //   name: "Exchange",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/exchange.svg").default,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/userSetting.svg").default,
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/pageSetting.svg").default,
  // },
  // {
  //   path: "/resetPassord",
  //   name: "Reset Passord",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/grid.svg").default,
  // },
  // {
  //   path: "/addSettings",
  //   name: "Add Settings",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/grid.svg").default,
  // },
  // {
  //   path: "/opportunities",
  //   name: "Opportunities",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/grid.svg").default,
  // },
  // {
  //   path: "/addSubscription",
  //   name: "Add Subscription",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/grid.svg").default,
  // },
];

export default nav;
